<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Refinancimiento de Crédito - Editar </strong>
            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/refinanciar-credito/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Cliente:</label>
                    <v-select placeholder="Seleccione un cliente" disabled class="w-100" :filterable="false" label="text" v-model="client"></v-select>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Codigo:">
                    <b-form-input readonly class="text-center" type="text" v-model="credit_refinance.code"></b-form-input>
                    <small v-if="errors.code"  class="form-text text-danger" >Seleccione un código</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Crédito Anterior:">
                    <b-form-input disabled type="text" class="text-center" v-model="credit_refinance.credit_code"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Crédito Nuevo:">
                    <b-form-input disabled type="text" class="text-center" v-model="credit_refinance.new_credit_code"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="credit_refinance.amount"></b-form-input>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Cuotas:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="credit_refinance.dues"></b-form-input>
                    <small v-if="errors.dues"  class="form-text text-danger" >Ingrese una cuota</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Pago:">
                    <b-form-input disabled type="date" class="text-center" v-model="credit_refinance.payment_date"></b-form-input>
                    <small v-if="errors.payment_date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nueva Fecha Pago :">
                    <b-form-input disabled type="date" class="text-center" v-model="credit_refinance.new_payment_date" :min="credit_refinance.payment_date"></b-form-input>
                    <small v-if="errors.new_payment_date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Doc. Adjunto:">
                    <b-form-file  accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"></b-form-file>
                  </b-form-group>
                </b-col>

                <b-col md="10">
                  <b-form-group label="Observación:">
                    <b-form-textarea v-model="credit_refinance.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Anexos:">
                     <b-button class="form-control" :disabled="credit_refinance.annexes.length == 0" @click="OpenAnnexes" type="button" variant="info">Ver Anexos</b-button>
                  </b-form-group>
                </b-col>
                
              

                <b-col md="12" v-if="credit_refinance.id_product == 2">
                  <div class="alert alert-dark" role="alert">
                    <strong>PAGOS DEL CAPITAL</strong> 
                  </div>
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="6%" class="text-center">#</th>
                          <th width="94%" class="text-center">Capital</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr v-for="(item, it) in credit_refinance.payment_capital" :key="it">
                          <td class="text-center">
                            <b-form-input size="sm" disabled type="number" step="any" class="text-center" v-model="item.dues"></b-form-input>
                          </td>
                          <td class="text-right"> 
                            <b-form-input size="sm" disabled type="number" step="any" class="text-right" v-model="item.amount"></b-form-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
               
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    <strong>CRONOGRAMA DE ṔAGO</strong> 
                  </div>


                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="17%" class="text-center">Cuota</th>
                          <th width="17%" class="text-center">Fecha</th>
                          <th width="17%" class="text-center">Capital</th>
                          <th width="17%" class="text-center">Interes</th>
                          <th width="17%" class="text-center">Saldo</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credit_refinance.credit_refinance_detail" :key="it">
                        <tr>
                          <td class="text-center">{{ item.quota_number }}</td>
                          <td class="text-right"> {{ item.quota  }}</td>
                          <td class="text-center"> {{ item.date  }}</td>
                          <td class="text-right"> {{ item.capital_balance  }}</td>
                          <td class="text-right"> {{ item.interest  }}</td>
                          <td class="text-right"> {{ item.balance  }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </b-col>

                

               

               

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>

   
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import EventBus from "@/assets/js/EventBus";
import ModalClients from './../components/ModalClient'
import ModalDisbursment from '@/views/disbursement/Add'
import ModalGuarantor from './../components/ModalGuarantor'
export default {
  name: "CreditEdit",
  props: ["id_credit_refinance"],
  components:{
    vSelect,
    ModalClients,
    ModalDisbursment,
    ModalGuarantor,
  },
  data() {
    return {
      module:'CreditRefinance',
      role:3,
      credit_refinance: {
          id_credit_refinance:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_product:'',
          id_credit:'',
          id_credit_detail:'',
          code:'',
          product_type : '',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          payment_date: '',
          new_payment_date: '',
          dues:'',
          annexes:'',
          observation:'',
          quota : '0.00',
          amortization : '0.00',
          amount:'0.00',
          state:1,
          quota_number : '',
          balance : '',
          interest : '',
          
          loan_detail : [],
          payment_capital : [],
          capital_diference:'0.00',
      },
      file:null,
      modules:[],
      client: null,
      errors: {
        id_client: false,
        payment_date: false,
        new_payment_date: false,
        total: false,
        id_credit:false,
        id_credit_detail: false,
        quota:false,
        amortization: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewCreditRefiance();
   
  },
  methods: {

    ViewCreditRefiance,
    Validate,
    EditCreditRefinance,
    onFileChange,
    OpenAnnexes,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    }
  },
};

function OpenAnnexes() {
  let me = this;
  let url = me.url_base + this.credit_refinance.annexes;
  window.open(url,'_blank');
}


function Validate() {

  this.errors.id_credit_refinance = this.credit_refinance.id_credit_refinance.length == 0 ? true : false;

  if (this.errors.id_credit_refinance) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el refinanciamiento de crédito ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditCreditRefinance();
    }
  });
}

function EditCreditRefinance() {
  let me = this;
  let data = new FormData();
  data.append("id_credit_refinance", this.credit_refinance.id_credit_refinance);
  data.append("annexes", this.credit_refinance.new_annexes);
  data.append("observation", this.credit_refinance.observation);
 
  let url = me.url_base + "credit-refinance/edit";

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_refinance.annexes = response.data.result.annexes; 
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}


function ViewCreditRefiance() {
  let me = this;
  let id_credit_refinance = je.decrypt(this.id_credit_refinance);
  let url = me.url_base + "credit-refinance/view/"+id_credit_refinance;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.client = { value: response.data.result.credit_refinance.id_client, text :  response.data.result.credit_refinance.client_name }
        me.credit_refinance.id_credit_refinance = response.data.result.credit_refinance.id_credit_refinance;
        me.credit_refinance.id_branch_office = response.data.result.credit_refinance.id_branch_office;
        me.credit_refinance.id_product = response.data.result.credit_refinance.id_product;
        me.credit_refinance.id_client = response.data.result.credit_refinance.id_client;
        me.credit_refinance.code = response.data.result.credit_refinance.code;
        me.credit_refinance.amount = response.data.result.credit_refinance.amount;
        me.credit_refinance.dues = response.data.result.credit_refinance.dues;
        me.credit_refinance.payment_date = response.data.result.credit_refinance.payment_date;
        me.credit_refinance.new_payment_date = response.data.result.credit_refinance.new_payment_date;
        me.credit_refinance.payment_capital = response.data.result.credit_refinance.payment_capital;
        me.credit_refinance.annexes = response.data.result.credit_refinance.annexes;
        me.credit_refinance.observation = response.data.result.credit_refinance.observation;
        me.credit_refinance.state = response.data.result.credit_refinance.state;
        me.credit_refinance.credits_details = response.data.result.credit_refinance.credits_details;
        me.credit_refinance.credit_code = response.data.result.credit_refinance.credit_code;
        me.credit_refinance.new_credit_code = response.data.result.credit_refinance.new_credit_code;

        me.credit_refinance.credit_refinance_detail = response.data.result.credit_refinance_detail;

        
      }
    });
}


function onFileChange(e) {
  this.credit_refinance.new_annexes = e.target.files[0];
}
</script>